import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/profile-picture.png";

const About = () => {
    return (
        <section id="about">
            <div className="about-section-container">
                <div className="about-background-image-container">
                    <img src={AboutBackground} alt="" />
                </div>
                <div className="about-section-image-container">
                    <img src={AboutBackgroundImage} alt="" />
                </div>
                <div className="about-section-text-container">
                    <p className="primary-subheading">Biography</p>
                    <p className="primary-text">
                        <strong>Justin Pfeifer</strong> is a jazz pianist, organist, composer, and arranger who has performed professionally for many years. He is versatile in a variety of musical idioms, including jazz, funk, blues, and rock. Growing up in the Roaring Fork Valley of Colorado provided him with many great musical opportunities, including studying with jazz trumpeter, pianist, and music educator <strong>Tim Fox</strong>.
                    </p>
                    <p className="primary-text">
                        Tim introduced Justin to the music of <strong>Miles Davis, John Coltrane, McCoy Tyner, Bill Evans,</strong> and <strong>Chick Corea</strong>, all of whom became significant influences on his musical development. Justin also had the privilege of studying with Grammy-nominated Hammond B3 jazz organist <strong>Pat Bianchi</strong> and performed with <strong>Lab Band One</strong> at the University of Northern Colorado.
                    </p>
                    <p className="primary-text">
                        Justin has toured internationally and throughout the western United States. He's performed with the <strong>MST Jazz Trio, Jes Grew, TJAAR, Let Them Roar, Lipbone Redding, Jeff Solon, The Rico Blues Project </strong> and many more. He is a freelance musician and currently resides in the Roaring Fork Valley in Colorado.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;