import React from 'react';
import Navbar from "./Navbar";
import BannerBackground from "../Assets/home-banner-background_2.png";

const Home = () => {
    return (
        <section id="home">
            <div className="home-container">
                <Navbar />
                <div className="home-banner-container">
                    <div className="home-bannerImage-container">
                        <img src={BannerBackground} alt="" />
                    </div>
                    <div className="home-text-section">
                        <h1 className="primary-heading">
                            Justin Pfeifer
                        </h1>
                        <p className="primary-text">
                            <div className="subheader">Pianist, Organist, & Composer</div>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        );
    }

export default Home;