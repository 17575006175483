import './App.css';
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Gallery from "./Components/Gallery";
import Events from "./Components/Events";
import Music from "./Components/Music";
import Learn from "./Components/Learn";
import Footer from "./Components/Footer";
import ScrollToTop from "react-scroll-to-top";
import {Container} from "@mui/material";


function App() {
    return (
        <div>
            <Container>
                <ScrollToTop
                    smooth={true}
                    height="18"
                    viewBox="0 0 24 24"
                    style={{ width: "36px" }}
                    svgPath="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"
                />
                <Home />
                <About />
{/*                <Music />*/}
                <Events />
                <Gallery />
                <Learn />
                <Contact />
            </Container>
            <Footer />

        </div>




    );
}

export default App;
