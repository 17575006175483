import * as React from 'react';
import { useEffect, useState } from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { months } from '../Utils/data';
import MapIcon from '@mui/icons-material/Map';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


const Events = () => {
    const [data, setData] = useState([]);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Defaults current month to be expanded in the accordion

    useEffect(() => {
        const url = "https://gleaming-blini-b266fc.netlify.app/.netlify/functions/api/events"

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                setData(data);
            } catch(error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, []);


    return (
        <section id='events' className="events-section-container">
            <div className="primary_subheading-container">
                <h1 className="primary-subheading">Shows</h1>
            </div>
            <div>
                {months.map(month => {
                    return (
                        <Accordion key={month.id}
                                   defaultExpanded={month.id === currentMonth}

                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-expanded={ false }
                                aria-controls={month.month_name}
                                id={month.id}
                                key={month.id}
                            >
                                <Typography variant='button'>{month.month_name} </Typography>
                            </AccordionSummary>
                            {data
                                .filter(event => event.month_name === month.month_name)
                                .sort(function(a,b) { return new Date(a.event_date) - new Date(b.event_date)})
                                .map(event => (
                                    <AccordionDetails key={event.id}>
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <MusicNoteIcon/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h5">
                                                        {event.event_name}
                                                    </Typography>
                                                    <Typography>
                                                        {event.venue}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <MapIcon/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {event.location}
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <EventIcon/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {event.event_date_formatted}
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <AccessTimeIcon/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {event.start_time} - {event.end_time}
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                ))}
                        </Accordion>

                    )
                })}
            </div>
        </section>
    )};
export default Events;