import React from "react";
import {Box, Stack, ImageList, ImageListItem} from "@mui/material";


const itemData = [
    {
        img: 'images/gallery_1.png',
        title: 'Aspen Snowmass Jazz Festival',
    },
    {
        img: 'images/gallery_2.png',
        title: 'With TJAAR',
    },
    {
        img: 'images/gallery_3.png',
        title: 'With the Rebel Jazz Band and Kenny Rampton'
    },
    {
        img: 'images/gallery_4.png',
        title: 'With Jeff Solon & John ONeal'
    },
    {
        img: 'images/gallery_5.png',
        title: 'With the Rebel Jazz Band and John Daversa'
    }
];

export default function Gallery() {
    return (
        <section id="gallery">
            <div className="gallery-section-container">
                <div className="primary_subheading-container">
                    <h1 className="primary-subheading">Gallery</h1>
                </div>
            </div>
                <div className="gallery-image-container">
                    <Box>
                        <Stack>
                            <ImageList variant='masonry' cols={3} gap={8}>
                                {itemData.map(item => (
                                    <ImageListItem key={item.img}>
                                        <img src={`${item.img}?w=248&fit=crop&auto=format&dpr=2`} alt={item.title} loading='lazy'/>
                                    </ImageListItem>
                                ))}

                            </ImageList>
                        </Stack>
                    </Box>




                </div>


        </section>
    );
}