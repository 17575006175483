import React, {Component} from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {Avatar, Container, ListItemAvatar} from "@mui/material";

class Learn extends Component {
    render() {
        return (
            <section id="learn">
                <div className="learn-section-container">
                    <div className="primary_subheading-container">
                        <h1 className="primary-subheading">Learn</h1>
                    </div>
                </div>
                <Container>
                    <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                    >
                        <List sx={{ width: '100%', maxWidth: "500px"}}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AutoStoriesIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="The Jazz Piano Book" secondary="by Mark Levine" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AutoStoriesIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="How to Play Bebop" secondary="by David Baker" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AutoStoriesIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Voicings for Jazz Keyboard" secondary="by Frank Mantooth" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AutoStoriesIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Aebersold Play-A-Longs" secondary="by Jamie Aebersold" />
                            </ListItem>


                        </List>
                    </Box>
                </Container>
            </section>
        );
    }
}

export default Learn;