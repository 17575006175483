import React, {useRef} from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Contact = () => {
    const form = useRef();


    const notify = () => toast("Thanks for reaching out.  Your message was sent successfully!", {
        position: toast.POSITION.BOTTOM_CENTER,
        className:'toast-message'
    });

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs
            .sendForm(
                "service_1l9wqf8",
                "template_4rhex5p",
                form.current,
                process.env.REACT_APP_EMAIL_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("message sent");
                    notify();
                },

                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <section id="contact">
                <div className="primary_subheading-container">
                    <h1 className="primary-subheading">Contact</h1>
                </div>
                <div className='contact-form-container'>
                    <form ref={form} onSubmit={sendEmail}>
                        <h3> Send a message</h3>
                        <h4>Let's get in touch</h4>
                        <fieldset>
                            <input placeholder="Your name" type="text" tabIndex="1" name="user_name" required />
                        </fieldset>
                        <fieldset>
                            <input placeholder="Your Email Address" type="email" name="user_email" tabIndex="2" required />
                        </fieldset>
                        <fieldset>
                            <input placeholder="Your Phone Number" name="user_phone" type="tel" tabIndex="3" required />
                        </fieldset>
                        <fieldset>
                            <textarea placeholder="Type your Message Here...." name="message" tabIndex="5" required></textarea>
                        </fieldset>
                        <fieldset>
                            <button name="submit" type="submit" id="contact-submit" className="secondary-button" data-submit="...Sending">Submit</button>
                        </fieldset>
                    </form>
                    <ToastContainer></ToastContainer>
                </div>
        </section>
    );
};

export default Contact;