/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LearnIcon from "@mui/icons-material/MenuBook";
import GalleryIcon from "@mui/icons-material/Camera";
import MusicIcon from "@mui/icons-material/LibraryMusic";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EventIcon from '@mui/icons-material/Event';
import { Link } from "react-scroll";

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const menuOptions = [
        {
            text: "Home",
            icon: <HomeIcon />,
            to: "home"
        },
        {
            text: "About",
            icon: <InfoIcon />,
            to: "about"
        },
/*        {
            text: "Music",
            icon: <MusicIcon />,
            to: "music"
        },*/
        {
            text: "Shows",
            icon: <EventIcon />,
            to: "events"
        },
        {
            text: "Gallery",
            icon: <GalleryIcon/>,
            to: "gallery"
        },
        {
            text: "Learn",
            icon: <LearnIcon/>,
            to: "learn"
        },
        {
            text: "Contact",
            icon: <PhoneRoundedIcon />,
            to: "contact"
        },
    ];

    return (
        <nav>
            <div className="nav-logo-container">
            </div>
            <div className="navbar-links-container">
                <Link
                    className="link"
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >Home
                </Link>
                <Link
                    className="link"
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >
                    About
                </Link>
{/*                <Link
                    className="link"
                    activeClass="active"
                    to="music"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >
                    Music

                </Link>*/}
                <Link
                    className="link"
                    activeClass="active"
                    to="events"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >
                    Shows
                </Link>

                <Link
                    className="link"
                    activeClass="active"
                    to="gallery"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >
                    Gallery
                </Link>
                <Link
                    className="link"
                    activeClass="active"
                    to="learn"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}
                >
                    Learn
                </Link>
                <Link
                    className="link"
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={750}>
                    <button className="primary-button">Contact</button>
                </Link>

            </div>
            <div className="navbar-menu-container">
                <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
            </div>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setOpenMenu(false)}
                    onKeyDown={() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <Link
                                    className="link"
                                    activeClass="active"
                                    to={item.to}
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={750}
                                >
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>

                                        <ListItemText primary={item.text} />

                                </ListItemButton>
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </nav>
    );
};

export default Navbar;